import React from 'react';

const Sietek = () => {
    return (
        <>
            <iframe
                title="7 K"
                src="sietek.html"
                width="100%"
                height="600px"
            />
        </>
    );
};

export default Sietek;