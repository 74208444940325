import React from 'react';

const Quincek = () => {
    return (
        <>
            <iframe
                title="15 K"
                src="quincek.html"
                width="100%"
                height="600px"
                zoom= "0.65"
            />
        </>
    );
};

export default Quincek;